import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { string } from "prop-types";

const useCopyToClipboard = (text, notifyTimeout = 2500) => {
  const [copyStatus, setCopyStatus] = useState("inactive");
  const copy = useCallback(() => {
    navigator.clipboard.writeText(text).then(
      () => setCopyStatus("copied"),
      () => setCopyStatus("failed")
    );
  }, [text]);

  useEffect(() => {
    if (copyStatus === "inactive") {
      return;
    }

    const timeoutId = setTimeout(
      () => setCopyStatus("inactive"),
      notifyTimeout
    );

    return () => clearTimeout(timeoutId);
  }, [copyStatus, notifyTimeout]);

  return [copyStatus, copy];
};

const CopyButton = ({ value, type, ...props }) => {
  const [copyStatus, copy] = useCopyToClipboard(value);
  var buttonText = `Copy ${type}`;

  if (copyStatus === "copied") {
    buttonText = "Copied";
  } else if (copyStatus === "failed") {
    buttonText = "Copy failed!";
  }

  return (
    <ButtonWrapper
      data-testid="CpyButton-Wrapper"
      {...props}
      color={type === "CSV" ? "#52b474;" : "#fff"}
      curl={type === "curl" ? "true" : "false"}
      type="button"
      onClick={copy}
      refType={type}
    >
      {buttonText}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ color }) => color};
  cursor: pointer;
  margin: ${({ curl }) => (curl === "true" ? "0xp" : "5px")};
  font-size: ${({ refType }) => refType === "CSV" && "18px"};
  ${({ curl }) =>
    curl &&
    `
    z-index: 4;
    text-align: end;
    margin-right: 15px;  `}
`;

CopyButton.propTypes = {
  type: string.isRequired,
  value: string
};

CopyButton.defaultProps = {
  value: ""
};

export default CopyButton;
