import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";
import UpArrow from "../svg/dropdown-arrow.svg";
import BurgerMenu from "../components/BurgerMenu";
import {scrollToSection} from '../helpers/utils.js'

import SimplifiedScreen from "../images/mail_simplified.png";
import BatchScreen from "../images/mail_batch.png";
import CsvFormat from "../images/csv_format.png";

import One from "../svg/numbers/num-contour-01.svg";
import Two from "../svg/numbers/num-contour-02.svg";
import Three from "../svg/numbers/num-contour-03.svg";
import Four from "../svg/numbers/num-contour-04.svg";

import Sms from "../svg/SMS.js";

import CopyButton from "../components/CopyToClipboard";

import {
  Title,
  SubTitle,
  Summary,
  Paragraph
} from "../components/PageElements/StaticElements";

import LinkParagraph from "../components/PageElements/StaticElements/InnerHtmlParagraph";
import Important from "../components/PageElements/StaticElements/Important";

const Mail2Sms = () => {
  const sections = ["Simplified Method", "Batch Method"];
  const [displayBurger, setDisplayBurger] = useState(false);

  useEffect(() => {
    const timerColor = setTimeout(() => {
      document.getElementById("sbc_color").style.display = "block";
    }, 1500);
    const timerStroke = setTimeout(() => {
      document.getElementById("sbc_stroke").style.display = "block";
    }, 1000);
    const timerAt = setTimeout(() => {
      if (document.getElementById("sbc_at"))
        document.getElementById("sbc_at").style.display = "block";
    }, 1000);
    return () => {
      clearTimeout(timerColor);
      clearTimeout(timerStroke);
      clearTimeout(timerAt);
    };
  }, []);

  const onScroll = () => {
    const target = document.getElementById("back2TopButton");
    if (target && window.pageYOffset > 850) target.style.display = "block";
    else if (target) target.style.display = "none";
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  const csvExample =
    "'Test this is an SMS sending test for %1, to (%2) Urgent! We have a mission for you  $ €$. Call us back on, (%3), stop $scod';'refClient123' '#-06xxxxxxxx';'smsmode Marseille';'Toto Jean';'04 91 05 64 61' '#-336xxxxxxxx';'smsmode Marseille';'Dupond Marc'; '04 91 05 64 63' '#-336xxxxxxxx';'smsmode Marseille';'Martin Hugo';'04 91 05 64 62' '#-336xxxxxxxx';'smsmode Marseille';'Petit Léa';'04 91 05 64 62' '#-06xxxxxxxx';'smsmode Paris';'Richard Jules'; '04 91 05 64 62' '#-06xxxxxxxx';'smsmode Paris';'Girard Paul';'04 91 05 64 62' '#-06xxxxxxxx';'smsmode Paris';'Roux Maëlle';'04 91 05 64 63' '#-06xxxxxxxx';'smsmode Paris';'Vidal Julie'; '04 91 05 64 63' '#-06xxxxxxxx';'smsmode Paris';'Rey Luc';'04 91 05 64 61' '#-06xxxxxxxx';'smsmode Paris';'Faure Yves';'04 91 05 64 61' '#-06xxxxxxxx';'smsmode Paris';'Boyer Loïc';'04 91 05 64 61'";

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>smsmode© | MAIL2SMS API Reference</title>
      </Helmet>
      <LeftContainer>
        <Summary>
          {sections.map((section, index) => (
            <button
              key={section}
              type="button"
              onClick={() => scrollToSection(section, -100)}
            >
              <p>{section}</p>
              <span>{index + 1}</span>
            </button>
          ))}
        </Summary>
      </LeftContainer>
      <BurgerMenu
        mail
        color="#52b474"
        onClick={() => setDisplayBurger(!displayBurger)}
        display={displayBurger}
        setDisplay={setDisplayBurger}
      >
        <Summary>
          {sections.map((section, index) => (
            <button
              key={section}
              type="button"
              onClick={() => {
                setDisplayBurger(false);
                scrollToTop(section, -100);
              }}
            >
              <p>{section}</p>
              <span>{index + 1}</span>
            </button>
          ))}
        </Summary>
      </BurgerMenu>
      <RightContainer>
        <TitleContainer>
          {/* <MovingWave className="WaveWrapper" color="#52b4743d" invert /> */}
          <SvgWrapper>
            <Sms />
          </SvgWrapper>
          <h1>API Mail2SMS</h1>
          <h2>Send your SMS with a simple e-mail</h2>
        </TitleContainer>
        <Container>
          <Title>Step by step</Title>
          <Paragraph mail>
            Simply send your SMS messages using an existing email. You can:
            <br />
            <p>
              <Dot /> send an SMS using a simple email.
            </p>
            <p>
              <Dot /> easily send an SMS to one or more recipients.
            </p>
            <p>
              <Dot /> follow up the sending status and statistics on your
              customer space.
            </p>
          </Paragraph>
          <LinkParagraph>
            {`Open an organisation on smsmode<sup>©</sup> <a href='https://ui.smsmode.com/home' target='_blank'
            rel='noopener noreferrer'>(click here)</a>. Credit your organisation from your
            personal space and prepare the email according to the required
            format (see following sections).`}
          </LinkParagraph>

          <Important
            text="To send SMS through our Mail2SMS API, you must have a specially configured smsmode© channel. You can do this  <a href='https://ui.smsmode.com/services/mailtosms' target='_blank'
            rel='noopener noreferrer'>here</a>."
          />
        </Container>
        <Section id={sections[0]}>
          <Title>
            <Number>1</Number>
            {sections[0]}
          </Title>
          <Paragraph mail>
            This method is a simplified method of sending an SMS from an email
            as no authentication settings are required, authentication is not
            required.
          </Paragraph>
          <Important text="You can send from a set of email addresses previously linked to the smsmode© organisation to be debited." />
          <SectionContainer reverse>
            <img src={SimplifiedScreen} alt="" />
            <div>
              <SubTitle>
                <div>
                  <One />
                </div>
                <div>
                  RECIPIENT'S NUMBER
                  <span>mandatory parameter</span>
                </div>
              </SubTitle>

              <Paragraph mail margin>
                Enter the recipient’s number followed by the address of the
                smsmode© module according to the following template:
                <br />
                "[recipient_mobile_number]@email2smsmode.com" or for example
                “0612345678@email2smsmode.com”. If you want to send your message
                to several recipients, fill in this field as many times as
                necessary, separated by a comma.
              </Paragraph>

              <SubTitle>
                <div>
                  <Two />
                </div>
                <div>
                  CUSTOMIZED TRANSMITTER
                  <span>optional parameter</span>
                </div>
              </SubTitle>
              <Paragraph mail margin>
                Must contain a maximum of 11 characters without spaces or
                special characters.
              </Paragraph>
              <SubTitle>
                <div>
                  <Three />
                </div>
                <div>
                  MESSAGE
                  <span>mandatory parameter</span>
                </div>
              </SubTitle>
              <Paragraph mail margin>
                Write the text of the SMS to be sent in the body of the message.
                The length is not limited and determines the number of SMS that
                will be sent and counted (from 1 to 160 characters: 1 SMS, 2 SMS
                up to 306 characters: 2 SMS, up to 459 characters: 3 SMS, up to
                612 characters: 4 SMS, etc.).
              </Paragraph>
            </div>
          </SectionContainer>
        </Section>
        <Section id={sections[1]}>
          <Title>
            <Number>2</Number>
            {sections[1]}
          </Title>
          <Paragraph mail>
            The second method is particularly suitable for sending SMS to
            several recipients including personalisation of messages. This
            method is based on a "Batch" mode, namely an email with an attached
            personalisation file containing the message and the recipient
            numbers, personalisation numbers and personalisation elements.
          </Paragraph>

          <SectionContainer>
            <img style={{ marginTop: "10px" }} src={BatchScreen} alt="" />
            <div>
              <SubTitle>
                <div>
                  <One />
                </div>
                <div>
                  RECIPIENT mandatory parameter
                  <span>mandatory parameter</span>
                </div>
              </SubTitle>
              <Paragraph mail margin>
                Enter the address of the smsmode<sup>©</sup> API
                "diffusion@email2smsmode.com".
              </Paragraph>
              <SubTitle>
                <div>
                  <Two />
                </div>
                <div>
                  SUBJECT
                  <span>optional parameter</span>
                </div>
              </SubTitle>
              <Paragraph mail margin>
                You can also enter the customised transmitter to be used.
                Maximum 11 characters with no spaces or special characters.
              </Paragraph>
              <SubTitle>
                <div>
                  <Three />
                </div>
                <div>
                  MESSAGE
                  <span>not taken into account</span>
                </div>
              </SubTitle>
              <SubTitle>
                <div>
                  <Four />
                </div>
                <div>
                  AS AN ATTACHMENT
                  <span>mandatory parameter</span>
                </div>
              </SubTitle>
              <Paragraph mail margin>
                Attach your customisation file in CSV format, formatted as
                described in the next section.
              </Paragraph>
            </div>
          </SectionContainer>
          <Important
            style={{ width: "100% !important" }}
            text="The application incorporates duplicate detection to prevent the same SMS from being sent several times: so an SMS with the same recipient and the same text cannot be sent if it has already been sent within the last 30 minutes."
          />
          <SectionContainer reverse style={{ marginTop: "50px" }}>
            <CsvWrapper>
              <CopyButton value={csvExample} type="CSV" />
              <img src={CsvFormat} alt="" />
            </CsvWrapper>
            <div>
              <SubTitle>
                <div>
                  <One />
                </div>
                <div>
                  FIRST LINE
                  <span>mandatory parameter</span>
                </div>
              </SubTitle>
              <Paragraph mail margin>
                Fill in the following elements in order:
                <br />
                <br />
                <span>CONTENT OF THE MESSAGE TO BE SENT</span> in the first
                column, which includes :<br />
                <br /> <span>VARIABLES</span> (%1), (%2), (%3), etc.
                <br />
                <span>SPECIAL CHARACTERS </span> (€, $, £, ¤) etc. <br />
                <span>SMS STOP</span> the word "$scod" is mandatory in any
                marketing message. To send transactional SMS without the STOP
                SMS mention, please contact our team.
                <br />
                <br />
                The length is not limited and determines the number of SMS
                messages that will be sent and counted (from 1 to 160
                characters: 1 SMS; from 161 to 306 characters: 2 SMS; from 307
                to 459 characters: 3 SMS, etc.).
                <br />
                <br />
                <span>
                  CUSTOMER OR CAMPAIGN REFERENCE CAMPAIGN (optional)
                </span>{" "}
                in the second column
              </Paragraph>
              <SubTitle>
                <div>
                  <Two />
                </div>
                <div>
                  FOLLOWING LINES
                  <span>mandatory parameter</span>
                </div>
              </SubTitle>
              <Paragraph mail margin>
                You must fill in the personalisation elements (1 line per
                recipient) and include in order :
                <br />
                <br />
                <span>THE RECIPIENT'S NUMBER</span> preceded by the 2 characters
                #-.
                <br />
                The numbers must not contain spaces, hyphens or dots; they can
                be either "33600000000" or "0600000000". "0600000000". <br />
                <br />
                <span>CONTENTS OF VARIABLES</span> (%1), (%2), (%3) etc.
                separated by a ";".
              </Paragraph>
            </div>
          </SectionContainer>
        </Section>
        <BackToTop id="back2TopButton" onClick={() => scrollToTop("", -100)}>
          <UpArrow />
        </BackToTop>
      </RightContainer>
    </Wrapper>
  );
};

const SvgWrapper = styled.div`
  & > svg {
    transform: rotate(10deg);
    height: 100%;
  }
`;

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BackToTop = styled.button`
  animation: ${fade} 0.7s;
  display: none;
  border: none;
  transition: ease 0.275s;
  &:hover {
    transform translateY(-.1em);
    box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  }
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => `${theme.second}`};
  border-radius: 50%;
  height: 70px;
  min-width: 70px;
  cursor: pointer;
  outline: none;
  position: fixed;
  right: 100px;
  bottom: 100px;
  z-index: 10;
  width: 50px;
  & > svg {
    height: 30px;
    width: 30px;
    transform: rotate(180deg);
  }
  @media (max-width: 900px) {
    right: 50px;
    bottom: 50px;
  }
`;

const CsvWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 20px;
  @media (max-width: 768px) {
    margin: 0px;
  }
  & > img {
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
    }
  }
`;

const SectionContainer = styled.div`
  & > img {
    opacity: 0.8;
    max-width: 600px;
    object-fit: contain;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
  display: flex;
  @media (max-width: 900px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  flex-direction: ${({ reverse }) => reverse && "row-reverse"};
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const LeftContainer = styled.div`
  position: fixed;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1040px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  @media (max-width: 1040px) {
    margin-left: 0;
    width: 100%;
    padding: 0;
  }
  margin-left: 300px;
  width: 100%;
  padding-left: 20px;
`;

const Section = styled.div`
  max-width: 1232px;
  margin-bottom: 100px;
  @media (max-width: 1300px) {
    margin-left: 10px;
  }
  @media (max-width: 1040px) {
    margin-left: 0;
    width: 100%;
    padding: 0 20px;
  }
`;

const TitleContainer = styled.div`
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h1 {
    font-size: 35px;
    font-weight: 500;
  }
  & > h2 {
    margin: 10px 0;
    font-size: 16px;
  }
`;

const Container = styled.div`
  max-width: 1232px;
  margin-bottom: 100px;
  @media (max-width: 1040px) {
    margin-left: 0;
    padding: 0 20px;
    width: 100%;
  }
`;

const Number = styled.div`
  background-color: #52b4743d;
  border-radius: 5px;
  height: 25px;
  max-width: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  margin-right: 15px;
`;

const Dot = styled.span`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #30cd6d;
  margin: 10px;
`;

export default Mail2Sms;
