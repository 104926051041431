import React, { useContext } from "react";
import styled from "styled-components";
import { Context } from "../../Layout/index";
import Info from "../../../svg/info.svg";
import InfoDark from "../../../svg/info-dark.svg";

const Important = ({ text }) => {
  const { currentTheme } = useContext(Context) || {};

  return (
    <Wrapper>
      <div>{currentTheme === "light" ? <Info /> : <InfoDark />}</div>
      <p
        id="text"
        dangerouslySetInnerHTML={{
          __html: `${text}`
        }}
      ></p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  background-color: ${({ theme }) => theme.second};
  border-radius: 5px;
  padding: 15px;
  margin: 20px 0;
  align-items: center;
  & > div {
    margin-right: 10px;
  }
  & > p {
    font-size: 14px;
    line-height: 25px;
  }
  & > p > span {
    font-weight: 900;
  }
  & > p > a {
    cursor: pointer;
    color: #52b474;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1040px) {
    min-width: 850px;
  }
`;

export default Important;
