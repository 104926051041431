import React from "react";
import styled from "styled-components";

const InnerHtmlParagraph = ({ children, buttonWrapper, ...props }) => {
  return (
    <Wrapper
      buttonWrapper={buttonWrapper}
      {...props}
      id="text"
      dangerouslySetInnerHTML={{
        __html: `${children}`
      }}
    />
  );
};

const Wrapper = styled.p`
  font-size: 14px;
  line-height: 25px;
  margin: 10px 0;
  padding-left: ${({ margin }) => (margin ? "80px" : "0")};
  & > span {
    font-weight: 900;
  }
  & > a {
    cursor: pointer;
    color: #52b474;
  }
  text-decoration: ${({ buttonWrapper }) => buttonWrapper && "underline"};
  cursor: ${({ buttonWrapper }) => buttonWrapper && "pointer"};
  color: ${({ buttonWrapper }) => buttonWrapper && "#52b474"};
`;

export default InnerHtmlParagraph;
